import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/kenneth/Repos/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://vancouverdesignwk.com"
      }}>{`Vancouver Design Week`}</a>{` is happening in September, and we’re excited to have Type Brigade be part of it with `}<a parentName="p" {...{
        "href": "http://walk.typebrigade.com/"
      }}>{`Type Walk`}</a>{` on the September 26th!`}</p>
    <p>{`The event costs only \\$15 if you `}<a parentName="p" {...{
        "href": "http://walk.typebrigade.com/"
      }}>{`register before September 10th`}</a>{`.`}</p>
    <figure {...{
      "className": "figure figure--breakout"
    }}>
      <span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1024px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/69f2eec5b84be176672142ef01147112/2bef9/blog-type-walk-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.50000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAACTklEQVR42mWR7UtTcRTH738T9CKwICl6YUXBLKwsLaIIH7CJS+iBXhSiEkRYZFOhtPJh0ynZ5l6oUzfnU3PTloxmaW7uwTvvvbvebe7p3nWvv3Oa+rIv57z5cj7wPXwpXgKO5WOa8lSFKl51abeiWKwuluuvkdoSueWJIiuyvL+EwB4i8a6Qphry9DbRlOLdM1SAja475n9+HfJZxzdtY3+sE5vzM/SkOb08x9nMnHfF79uIbIXF4EZiaSa7OJ12WHed9tSsBe9fpBJSLm4xsq1NmcEPoqkzZtYlZyeU3lYc+QTGDvQ4ABFyEtrNMNwOpi40deJIFw504M1CyusP2pbc/Zap32F63D7XPWyadrjYaBTzDOTB/3RoZpL4vpkS3Yvyx9e5Xm1Op1WMnUmGEfcQJo3kcwsMtEG/FvrbwNAOei3o3+UH+95izxsIrBNEymOddH8xLBr069axtO9XKBgK0xFhbZX7Zg/ZJnjnQuKHK/bd6bdNsUsuzrmAkRBwEVCUfALKO2Nfo1khlQ5vM0KUj8TiQjrD7Ah0dIcV4lwsEaBZ/za/ycYiDM9x/OEzB9GBStSXJZs0coNa7Gj+m0mJIzrlhSanfZh9+UBqqMs11mWeq3efqVMN6oxnObWHUjYriaKiKHmagvJCUnYSVUexsQb1r7CqCG8VYvUpvFGAVwvw8jEsPQ6qI2R0MH/udC0PGQx9PT2W0THYh+tKSOUFqFJBbQk+voKPSvFOEZSdhopzqCnGyvNw7yy5fkI2dkuIghBnGGZri44e1PEPfQzSub+yr2QAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blog type walk 1",
            "title": "blog type walk 1",
            "src": "/static/69f2eec5b84be176672142ef01147112/2bef9/blog-type-walk-1.png",
            "srcSet": ["/static/69f2eec5b84be176672142ef01147112/e17e5/blog-type-walk-1.png 400w", "/static/69f2eec5b84be176672142ef01147112/5a190/blog-type-walk-1.png 800w", "/static/69f2eec5b84be176672142ef01147112/2bef9/blog-type-walk-1.png 1024w"],
            "sizes": "(max-width: 1024px) 100vw, 1024px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
      <figcaption parentName="figure">
        <a parentName="figcaption" {...{
          "href": "https://www.flickr.com/photos/kwl/"
        }}>{`Photo CC BY 2.0 Kenny Louie.`}</a>
      </figcaption>
    </figure>
    <p>{`Type Brigade is `}<a parentName="p" {...{
        "href": "http://meetup.com/typebrigade"
      }}>{`Vancouver’s type design and typography meetup`}</a>{`, where we host speakers every other month. This `}<a parentName="p" {...{
        "href": "http://walk.typebrigade.com"
      }}>{`Type Walk`}</a>{` is an off-month special event to coincide with Vancouver Design Week.`}</p>
    <h2>{`The Workshop`}</h2>
    <p><em parentName="p">{`6:00–8:00PM`}</em></p>
    <p>{`The first portion of the evening will be a workshop hosted by `}<a parentName="p" {...{
        "href": "http://workingformat.com"
      }}>{`Ross Milne of Working Format`}</a>{`. Ross studied type design at the internationally acclaimed Type & Media masters course at the Royal Academy of Art (KABK), is a contributing designer with Commercial Type in New York, and with Typotheque in the Hague, Netherlands.`}</p>
    <figure {...{
      "className": "figure"
    }}>
      <span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/030efa6fe6b57a5493430057e35d3844/80e3c/blog-type-walk-workshop.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIEBf/EABQBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAb5lB0hRP//EABsQAAIDAAMAAAAAAAAAAAAAAAERAAIDBBIi/9oACAEBAAEFAhU9X7yLzanHB3oMVP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABoQAAIDAQEAAAAAAAAAAAAAAAARAQJhEjL/2gAIAQEABj8C6aLX0jCyFMpHqx//xAAcEAACAgIDAAAAAAAAAAAAAAABEQAhMVFBYXH/2gAIAQEAAT8hsw6CKOIQo+QanQjUa5gkQoTaDMCyuLpT/9oADAMBAAIAAwAAABBD3//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPxCRr//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAgEBPxC1j//EAB0QAQEAAgMAAwAAAAAAAAAAAAERACFBUYFhkeH/2gAIAQEAAT8QcJwdIX3jIIEwsBe3Ws1OEfOHGO8JPd3f7iB1qnMn04fDs6BXzP/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blog type walk workshop",
            "title": "blog type walk workshop",
            "src": "/static/030efa6fe6b57a5493430057e35d3844/80e3c/blog-type-walk-workshop.jpg",
            "srcSet": ["/static/030efa6fe6b57a5493430057e35d3844/066f9/blog-type-walk-workshop.jpg 400w", "/static/030efa6fe6b57a5493430057e35d3844/80e3c/blog-type-walk-workshop.jpg 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
      <figcaption parentName="figure">{`A previous type workshop by `}<a parentName="figcaption" {...{
          "href": "http://twitter.com/workingformat"
        }}>{`Working Format`}</a>{`.`}</figcaption>
    </figure>
    <p>{`This will take place at Chloi HQ, the `}<a parentName="p" {...{
        "href": "http://chloi.io"
      }}>{`Chloi Inc.`}</a>{` office.`}</p>
    <h2>{`The Walk`}</h2>
    <p><em parentName="p">{`8:30–9:30PM`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.google.ca/maps/place/55+E+Cordova+St,+Vancouver,+BC+V6A+1K3/@49.2824697,-123.1034229,17z"
      }}>{`Chloi HQ is in Gastown`}</a>{`, which makes it only a short walk to the second half of the evening: the walk! The Type Walk itself will be hosted by `}<a parentName="p" {...{
        "href": "http://christiandahlberg.com/"
      }}>{`Christian Dahlberg`}</a>{` of Vancouver Neon.`}</p>
    <h2>{`About Vancouver Design Week`}</h2>
    <p>{`Among other things, Vancouver Design Week is looking to`}</p>
    <blockquote>
      <p parentName="blockquote">{`cross-pollenate and empower designers and design-thinkers across disciplines … in Vancouver, and beyond.
`}<strong parentName="p">{`Vancouver Design Week`}</strong></p>
    </blockquote>
    <p>{`This is something particularly important to us with Type Brigade. Type has a place in most disciplines, and is a great way to start conversations about design.`}</p>
    <h2>{`Type Walk`}</h2>
    <p>{`Hope to see you on September 26th!`}</p>
    <a href="http://walk.typebrigade.com" className="button button--small">Join the Type Walk</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      